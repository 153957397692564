import { HttpHeaders } from '@angular/common/http';
import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { APIErrorHandler } from './api-error-handler.service';
import { AppConfigService } from '@app/app-config.service';
import { MsalService } from '@azure/msal-angular';
var APIInterceptor = /** @class */ (function () {
    function APIInterceptor(_appConfig, errorHandler, msalService) {
        this._appConfig = _appConfig;
        this.errorHandler = errorHandler;
        this.msalService = msalService;
        this._config = this._appConfig.getConfig();
    }
    APIInterceptor.prototype.intercept = function (request, next) {
        var _this = this;
        var requestUrl = request.url;
        return from(this.msalService.acquireTokenSilent({
            scopes: ['https://EMCloudAD.onmicrosoft.com/42172299-8333-48b8-b5cc-dfffe370a6b4/user_impersonation']
        })).pipe(switchMap(function (token) {
            if (requestUrl.includes(_this._config.datascience_api_baseurl) || requestUrl.includes(_this._config.geoBertAPIUrl)) {
                if (!_this._config.datascience_api_token) {
                    request = request.clone({
                        headers: new HttpHeaders({
                            'Cache-Control': 'no-cache',
                            'Pragma': 'no-cache'
                        }),
                        withCredentials: true
                    });
                }
                else {
                    request = request.clone({
                        headers: new HttpHeaders({
                            'Cache-Control': 'no-cache',
                            'Pragma': 'no-cache'
                        }),
                        setHeaders: {
                            'Authorization': 'Bearer ' + token.accessToken
                        }
                    });
                }
            }
            return next.handle(request);
        }));
    };
    return APIInterceptor;
}());
export { APIInterceptor };
