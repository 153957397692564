import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable,from } from 'rxjs';
import { switchMap,catchError } from 'rxjs/operators';
import { APIErrorHandler } from './api-error-handler.service';
import { AppConfigService, AppConfig } from '@app/app-config.service';
import { MsalService  } from '@azure/msal-angular';

@Injectable()
export class APIInterceptor implements HttpInterceptor {
  private _config: AppConfig;

  constructor(
    private _appConfig: AppConfigService,
    public errorHandler: APIErrorHandler,
    private msalService:MsalService
  ) {
    this._config = this._appConfig.getConfig();
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const requestUrl = request.url;
    return from(this.msalService.acquireTokenSilent({
       scopes:['https://EMCloudAD.onmicrosoft.com/42172299-8333-48b8-b5cc-dfffe370a6b4/user_impersonation']
    })).pipe(switchMap((token:any)=>{
      if (requestUrl.includes(this._config.datascience_api_baseurl) || requestUrl.includes(this._config.geoBertAPIUrl)) {
        if (!this._config.datascience_api_token) {
          request = request.clone({
            headers: new HttpHeaders({
              'Cache-Control': 'no-cache',
              'Pragma': 'no-cache'
            }),
            withCredentials: true
          });
        } else {
          request = request.clone({
            headers: new HttpHeaders({
              'Cache-Control': 'no-cache',
              'Pragma': 'no-cache'
            }),
            setHeaders: {
              'Authorization' : 'Bearer '+token.accessToken
            }
          });
        }
      }
      return next.handle(request);
    }));
  }
}
